import React from "react";
import "../../styles/index.css";

export default function Video(props) {
  // const {videoID, videoDetail} = props;
  // console.log(videoID);
  // console.log(videoDetail);
				const videoSRC = "https://www.youtube.com/embed/vs1YgX0AmW4";
  return (
    <div className="flex justify-center w-full">
      <iframe
        className="w-10/12 h-64 offset-image-sm lg:offset-image-lg lg:h-screen"
        title="Latest Message"
        src={videoSRC}
        allowFullScreen={true}
      />
    </div>
  );
}
