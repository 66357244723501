import React from "react";
import "../../styles/index.css";
import children1 from "../../assets/images/children-1.jpg";
import men1 from "../../assets/images/men-1.jpg";
import leadership from "../../assets/images/leadership-1.jpg";
import women1 from "../../assets/images/women1.jpg";
import { FaFacebookSquare } from "react-icons/fa";
export default function Ministry() {
	return (
		<div className="flex flex-col w-full">
			<div class="flex justify-end flex-wrap my-12">
				<div
					style={{ zIndex: 999 }}
					className="w-3/5 h-auto py-2 text-2xl text-white uppercase xl:text-4xl font-roboto font lg:w-1/2 head-section-sm md:head-section-lg bg-primary lg:h-16"
				>
					<h1 className="ml-8 font-bold">
						<span className="font-hairline">
							wole
						</span>{" "}
						joseph ministries int'l
					</h1>
				</div>
			</div>

			<div class="flex w-full px-6 justify-center items-center flex-wrap mb-20">
				<div class="w-full md:w-1/2 shadow-2xl rounded-lg">
					<img
						src={leadership}
						alt="WJMI Ministry"
					/>
				</div>

				<div class="w-full md:w-1/2 px-6 text-lightBlack">
					<h4 class="flex capitalize font-montserrat text-3xl justify-center font-bold">
						The Leadership Ministry
					</h4>
					<div class="font-museo text-xl">
						<span>
							The Wole Joseph
							Ministries, is the
							leadership training arm
							for men and women. It
							aims to groom all those
							who are in leadership
							roles as well as those
							who are aspiring to get
							into leadership.
						</span>
					</div>
					{/*<div className="flex items-center py-2">*/}
					{/*<span className="text-xl font-museo">*/}
					{/*Connect with Travail Of*/}
					{/*Hannah*/}
					{/*</span>*/}
					{/*<div className="px-2">*/}
					{/*<a*/}
					{/*href="https://www.facebook.com/travailofhannah/"*/}
					{/*target="_blank"*/}
					{/*className="text-4xl"*/}
					{/*>*/}
					{/*<FaFacebookSquare />*/}
					{/*</a>*/}
					{/*</div>*/}
					{/*</div>*/}
				</div>
			</div>
			<div class="flex justify-end flex-wrap my-12 sticky top-0">
				<div
					style={{ zIndex: 999 }}
					className="w-3/5 h-auto py-2 mt-10 text-2xl text-white uppercase xl:text-4xl font-roboto font lg:w-1/2 head-section-sm md:head-section-lg bg-primary lg:h-16 "
				>
					<h1 className="px-6 ml-8 font-bold">
						<span className="font-hairline">
							The
						</span>{" "}
						men's ministry
					</h1>
				</div>
			</div>

			<div class="flex items-center flex-wrap justify-center mb-20 px-6">
				<div class="w-full md:w-1/2">
					<div class="shadow-2xl rounded-lg m-2 ">
						<img
							src={men1}
							alt="Men's Ministry"
						/>
					</div>
				</div>

				<div class="w-full md:w-1/2 px-6 text-lightBlack">
					<h4 class="capitalize font-montserrat text-3xl flex justify-center font-bold">
						The man and the family{" "}
					</h4>
					<div className="text-xl font-museo">
						<span>
							This is the men's
							fellowship arm of the
							ministry known as the
							men of honour. Being
							heads of families they
							have a significant role
							in the restoration
							mandate in raising and
							leading their families
							in honour as described
							in scripture. They hold
							weekly prayer meetings
							where various issues
							that is family related,
							work related and career
							related are tabled
							before God. This has
							fostered unity amongst
							men and birthed
							testimonies of ease in
							project accomplishment.
						</span>
					</div>
				</div>
			</div>
			<div class="flex justify-end flex-wrap my-12 sticky top-0">
				<div
					style={{ zIndex: 999 }}
					className="w-3/5 h-auto py-2 mt-10 text-2xl text-white uppercase xl:text-4xl font-roboto font lg:w-1/2 head-section-sm md:head-section-lg bg-primary lg:h-16 "
				>
					<h1 className="px-6 ml-8 font-bold">
						<span className="font-hairline">
							The
						</span>{" "}
						women's ministry
					</h1>
				</div>
			</div>

			<div class="flex w-full px-6 justify-center items-center flex-wrap mb-20">
				<div class="w-full md:w-1/2 shadow-2xl rounded-lg">
					<img
						src={women1}
						alt="Women's Ministry"
					/>
				</div>

				<div class="w-full md:w-1/2 px-6 text-lightBlack">
					<h4 class="flex capitalize font-montserrat text-3xl justify-center font-bold ">
						The woman and the word
					</h4>
					<div class="font-museo text-xl">
						<span>
							The women's minisry, led
							by Pastor Mrs.Wole
							Joseph is focused on the
							fellowship and
							unification of the
							Dominion Sisters group
							with a common goal:
							Restoration of homes.
							This has created a
							platform for all women
							to access empowerment
							through the running of
							annual leadership,
							mentorship as well as
							fellowship meetings.
						</span>
					</div>
					<div class="font-museo text-xl py-2">
						<span>
							The women's ministry has
							also expanded beyond the
							church to accomodate
							women from many
							denominations as well as
							different churches
							through the Travail Of
							Hannah women's group.
						</span>
					</div>

					<div className="flex items-center py-2">
						<span className="text-xl font-museo">
							Connect with Travail Of
							Hannah
						</span>
						<div className="px-2">
							<a
								href="https://www.facebook.com/travailofhannah/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-4xl"
							>
								<FaFacebookSquare />
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="flex justify-end flex-wrap my-12 sticky top-0">
				<div
					style={{ zIndex: 999 }}
					className="w-3/5 h-auto py-2 mt-10 text-2xl text-white uppercase xl:text-4xl font-roboto font lg:w-1/2 head-section-sm md:head-section-lg bg-primary lg:h-16 "
				>
					<h1 className="px-6 ml-8 font-bold">
						<span className="font-hairline">
							The
						</span>{" "}
						Children's Ministry
					</h1>
				</div>
			</div>

			<div class="flex justify-center px-6  items-center flex-wrap mb-20">
				<div class="w-full md:w-1/2 shadow-2xl rounded-lg">
					<img src={children1} alt="Us" />
				</div>

				<div class="w-full md:w-1/2 lg:px-6 text-lightBlack">
					<h4 class="flex capitalize font-montserrat text-3xl  justify-center font-bold">
						Our future generation
					</h4>
					<div class="font-museo text-xl">
						<span>
							The children's ministry
							comprises of the Sunday
							School and the Teen's
							Church where a solid
							foundation is laid in
							teaching them the word
							of God from an early age
							until the graduate to
							Teen's church and there
							after to the main
							church.
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
