import React from "react";
import "../../styles/index.css";
import { FaTwitter, FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { TwitterTimelineEmbed } from "react-twitter-embed"; import { FacebookProvider, Page } from "react-facebook"; import InstagramEmbed from "react-instagram-embed"; function SocialFeeds() { const appID = process.env.REACT_FACEBOOK_APPID;
  return (
    <div className="flex flex-col w-full pb-32">
      <div className="flex justify-end my-12">
        <div className="flex items-center w-9/12 h-auto py-2  bg-primary head-section-sm md:head-section-lg lg:h-16">
          <span className="ml-8 text-2xl font-hairline text-white uppercase font-roboto xl:text-4xl">
            Connect with{" "}
            <span className="font-bold">Breakthrough Chapel International</span>
          </span>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center lg:flex-row lg:items-start">
        <div className="w-11/12 px-4 py-6 mb-4 mr-0 rounded-lg xl:w-1/4 bg-lightGrey lg:mb-0 lg:mr-8">
          <div className="flex items-center py-2 text-3xl text-primary">
            <FaTwitter />
            <span className="px-3 font-roboto">Twitter</span>
          </div>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="breakthrough254"
            options={{ height: 450 }}
          />
        </div>
        <div className="w-11/12 px-4 py-6 mb-4 mr-0 rounded-lg xl:w-1/4 bg-lightGrey lg:mb-0 lg:mr-8">
          <div className="flex items-center py-2 text-3xl text-primary">
            <FaFacebookSquare />
            <span className="px-3 font-roboto">Facebook</span>
          </div>
         <div>
            <FacebookProvider appId={{ appID }}>
              <Page
                href="https://www.facebook.com/breakthroughchapelintl/"
                tabs="timeline"
              />
            </FacebookProvider>
         </div>
        </div>
        <div className="flex flex-col w-11/12 px-4 py-6 rounded-lg lg:flex-wrap xl:w-1/4 bg-lightGrey">
          <div className="flex items-center py-2 text-3xl text-primary">
            <FaInstagram />
            <span className="px-3 font-roboto"> Instagram</span>
          </div>
          <div>
            <InstagramEmbed
              url="https://www.instagram.com/p/CA4-SAPH72m/"
              maxWidth={undefined}
              hideCaption={true}
              containerTagName="div"
              protocol=""
              injectScript
              onLoading={() => {}}
              onSuccess={() => {}}
              onAfterRender={() => {}}
              onFailure={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialFeeds;
