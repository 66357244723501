import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import FormField from "../presentational/Form/FormField";
import PhoneInput from "react-phone-input-2";
//import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import "../../styles/index.css";
import "react-phone-input-2/lib/style.css";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
export default function Form() {
	const buttonRef = useRef();
	const [formValues, setFormValues] = useState({
		fname: "",
		lname: "",
		email: "",
		phoneNumber: "",
		message: ""
	});
	const [formMessage, setFormMessage] = useState("");
	const [formSubmitLoading, setFormSubmitLoading] = useState(false);
	const { fname, lname, email, phoneNumber, message } = formValues;
	const noEmptyField =
		fname !== "" &&
		lname !== "" &&
		email !== "" &&
		phoneNumber !== "" &&
		message !== "";
	useEffect(() => {
		if (!noEmptyField) {
			buttonRef.current.setAttribute("disabled", "");
			buttonRef.current.classList.add(
				"opacity-75",
				"cursor-not-allowed"
			);
			setFormMessage("Please fill in all fields");
		} else if (noEmptyField) {
			setFormMessage("");
			buttonRef.current.removeAttribute("disabled");
			buttonRef.current.classList.remove(
				"opacity-75",
				"cursor-not-allowed"
			);
		}
	}, [noEmptyField]);
	const handleFormField = e => {
		setFormValues({
			...formValues,
			[e.target.id]: e.target.value
		});
	};
	const submit = () => {
		setFormSubmitLoading(true);
		axios({
			method: "POST",
			url:
				"https://us-central1-bci-server.cloudfunctions.net/helloWorld/form/contact_form",
			data: {
				fname: formValues.fname,
				lname: formValues.lname,
				phoneNumber: formValues.phoneNumber,
				email: formValues.email,
				message: formValues.message
			}
		}).then(res => {
			if (res.data.msg === "success") {
				setFormSubmitLoading(false);
				//setFormMessage("Message Sent Successfully");
				NotificationManager.success(
					"Message sent successfully",
					"Success"
				);
				setFormValues({
					...formValues,
					fname: "",
					lname: "",
					email: "",
					phoneNumber: "",
					message: ""
				});
			} else if (res.data.msg === "fail") {
				setFormSubmitLoading(false);
				setFormMessage(
					"An error occured, please try again later"
				);
				setFormValues({
					...formValues,
					fname: "",
					lname: "",
					email: "",
					phoneNumber: "",
					message: ""
				});
			}
		});
	};
	return (
		<div
			className="relative flex flex-col w-10/12 h-full p-4 bg-white rounded-lg shadow-2xl xl:w-2/5 lg:h-60 z-1"
			style={{ margin: "10%" }}
		>
			<div className="flex justify-center w-full py-2">
				<span className="font-semibold text-secondary font-lg font-museo">
					{formMessage}
				</span>
				<BeatLoader loading={formSubmitLoading} />
			</div>
			<FormField
				label="Your first name"
				type="text"
				value={formValues.fname}
				handleChange={handleFormField}
				name="fname"
			/>
			<FormField
				label="Your last name"
				type="text"
				value={formValues.lname}
				handleChange={handleFormField}
				name="lname"
			/>
			<FormField
				label="Your email address"
				type="email"
				value={formValues.email}
				handleChange={handleFormField}
				name="email"
			/>
			<div className="flex flex-col items-center w-full py-2 text-lg jusxtify-between xl:flex-row">
				<label className="font-semibold tracking-wider font-museo text-secondary">
					Your phone number
				</label>
				<PhoneInput
					containerStyle={{ width: "66.66%" }}
					inputStyle={{ width: "100%" }}
					inputProps={{ required: true }}
					country={"ke"}
					placeholder="+254 700 000 000"
					value={formValues.phoneNumber}
					onChange={phone =>
						setFormValues({
							...formValues,
							phoneNumber: phone
						})
					}
				/>
			</div>
			<div className="flex flex-col items-center justify-between w-full py-2 text-lg xl:flex-row">
				<label
					className="font-semibold tracking-wider font-museo text-secondary"
					htmlFor="question"
				>
					Your question
				</label>
				<textarea
					className="w-4/5 p-2 text-sm border-2 resize-none font-montserrat lg:w-2/3 focus:outline-none rounded-md border-lightGrey"
					id="message"
					onChange={handleFormField}
					value={formValues.message}
					required
				/>
			</div>
			<div className="absolute inset-x-0 bottom-0 p-6">
				<button
					type="button"
					ref={buttonRef}
					className="w-full py-4 text-white uppercase rounded-full focus:outline-none font-montserrat bg-primary"
					onClick={submit}
				>
					Send Message
				</button>
			</div>
		</div>
	);
}
