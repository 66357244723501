import React from "react";
import "../../styles/index.css";
import about from "../../assets/images/about-hero-crop-1.jpg";
import ourPastors from "../../assets/images/Banner-10.jpg";
import word from "../../assets/images/the-word.png";
import prayer from "../../assets/images/prayer.png";
import discover from "../../assets/images/discover.png";
import excellence from "../../assets/images/excellence.png";

function About() {
	return (
		<div className="flex flex-col w-full">
			<div
				className="flex items-center justify-center w-full h-64 bg-no-repeat bg-cover filter-gray"
				style={{ backgroundImage: `url(${about})` }}
			>
				<div className="p-4">
					<span className="text-3xl font-bold text-white lg:text-6xl font-museo">
						Who We Are
					</span>
				</div>
			</div>
			<div className="flex flex-wrap w-full lg:p-20">
				<div className="flex flex-col w-full px-6 py-4 lg:w-1/2">
					<div>
						<span className="text-2xl font-semibold font-montserrat text-lightBlack">
							Our Mission
						</span>
					</div>
					<div>
						<span className="text-lg font-light font-museo">
							Our mission as a church
							is to raise men and
							women to power,
							dominion, dignity and
							honour- through church
							operation, in the cities
							of East Africa and
							beyond.
						</span>
					</div>
					<div className="py-2 text-lg text-primary font-museo">
						<a
							href="https://www.biblegateway.com/passage/?search=Genesis+1%3A26-28&version=KJV"
							rel="noopener noreferrer"
							target="_blank"
						>
							<span>
								Genesis 1:26-28
							</span>
						</a>
					</div>
				</div>
				<div className="flex flex-col w-full px-6 py-4 lg:w-1/2">
					<div>
						<span className="text-2xl font-semibold font-montserrat text-lightBlack">
							Our Vision
						</span>
					</div>
					<div>
						<span className="text-lg font-light font-museo">
							Go and restore power,
							dominion and honour to
							humanity, by preaching
							the word of faith and
							hope. Raise for men and
							women of Dominion,
							Power, Dignity and
							Honour.
						</span>
					</div>
					<div className="py-2 text-lg text-primary font-museo">
						<a
							href="https://www.biblegateway.com/passage/?search=Isaiah+42%3A22&version=KJV"
							rel="noopener noreferrer"
							target="_blank"
						>
							<span>
								Isaiah 42:22
							</span>
						</a>
					</div>
				</div>
			</div>
			<div className="flex flex-col w-full p-6 bg-lightGrey lg:p-20">
				<div className="flex justify-center w-full">
					<span className="text-3xl font-semibold font-montserrat text-secondary">
						Our Values
					</span>
				</div>
				<div className="flex flex-col items-center w-full p-2 lg:flex-row lg:text-2xl">
					<div className="flex items-center justify-center w-4/5 py-6 lg:w-1/2">
						<img
							src={word}
							alt="The Word"
							className="w-2/12"
						/>
						<div className="flex flex-col justify-center px-6">
							<div>
								<span className="font-semibold font-montserrat">
									The Word
								</span>
							</div>
						</div>
					</div>
					<div className="flex items-center justify-center w-4/5 py-6 lg:w-1/2">
						<img
							src={prayer}
							alt="Prayer"
							className="w-2/12"
						/>
						<div className="flex flex-col justify-center px-6">
							<div>
								<span className="font-semibold font-montserrat">
									Prayer
								</span>
							</div>
						</div>
					</div>
					<div className="flex items-center justify-center w-4/5 py-6 lg:w-1/2">
						<img
							src={discover}
							alt="Discovery"
							className="w-2/12"
						/>
						<div className="flex flex-col justify-center px-6">
							<div>
								<span className="font-semibold font-montserrat">
									Discovery
								</span>
							</div>
						</div>
					</div>
					<div className="flex items-center justify-center w-4/5 py-6 lg:w-1/2">
						<img
							src={excellence}
							alt="Excellence"
							className="w-2/12"
						/>
						<div className="flex flex-col justify-center px-6">
							<div>
								<span className="font-semibold font-montserrat">
									Excellence
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-wrap w-full">
				<div className="flex flex-col w-full lg:p-20 lg:w-1/2">
					<div className="p-6">
						<span className="text-2xl font-semibold font-montserrat">
							Our Pastors
						</span>
					</div>
					<div className="w-full px-6">
						<img
							className="w-full"
							src={ourPastors}
							alt="Our Pastors"
						/>
					</div>
				</div>
				<div className="flex flex-col justify-center w-full lg:w-1/2">
					<div className="p-6 lg:px-12">
						<span className="text-lg font-light font-museo">
							Our senior pastor,
							Pastor Wole Joseph
							received the calling
							from God to start
							Dominion Ministries
							International also known
							as Breakthrough Chapel
							International which was
							founded in Kenya on 9
							August 2003. Before this
							he served faithfully
							under Faith Liberation
							Hour Ministry popularly
							known as Winners Chapel
							under His Lordship
							Bishop David Oyedepo.
							His Lordship Bishop
							David Oyedepo formally
							and ceremonially
							released Pastor Wole
							Joseph on 23rd July 2003
							in Canaanland Otta
							Lagos, Nigeria. Pastor
							Wole Joseph is happily
							married to Pastor Favour
							Wole Joseph who is the
							resident pastor for the
							Mombasa branch.{" "}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About;
