import React from "react";
import "../../../styles/index.css";

export default function VideoPlayer(props) {
	const { video } = props;
	return (
		<video
			className="w-full focus:outline-none h-h40vh lg:h-h80vh"
			//style={{ height: "40vh" }}
			src={video}
			controls
		/>
	);
}
