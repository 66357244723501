import React from "react";
import "../../styles/index.css";

function Location() {
	const API_KEY = process.env.REACT_APP_GOOGLE_API;
	const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=Breakthrough+Chapel+Mombasa`;
	return (
		<div className="w-full">
			<div
				style={{ zIndex: 998 }}
				className="absolute right-0 float-right w-4/5 h-auto py-2 mt-10 text-2xl text-white uppercase xl:text-4xl font-roboto font lg:w-1/2 head-section-sm md:head-section-lg bg-primary lg:h-16"
			>
				<h1 className="ml-8 font-bold">
					<span className="font-hairline">
						Location of
					</span>{" "}
					Breakthrough Chapel
				</h1>
			</div>
			<iframe
				title="Breakthrough Chapel HQ"
				className="w-full"
				// width="600"
				height="450"
				frameBorder="0"
				// style={{ border: 0 }}
				src={mapUrl}
				allowFullScreen
			></iframe>
		</div>
	);
}

export default Location;
