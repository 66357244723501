import React from "react";
// import youtube from "../../apis/youtube";
import Video from "../ui/Video";
import "../../styles/index.css";

export default function LatestMessageSection() {
  // const [videoID, setVideoID] = useState("");
  // const [videoDetail, setVideoDetail] = useState({});
  //   youtube
  //     .get("/search", {
  //       params: {
  //         part: "snippet",
  //         channelId: "UC8v3udD65nMBbLYFfhJJmoA",
  //         maxResults: 1,
  //         order: "date",
  //         key: process.env.REACT_APP_GOOGLE_API,
  //       },
  //     })
  //     .then((response) => {
  //       console.log(response.data.items[0]);
  //     //   setVideoID(response.data.items[0].id.videoId);
  //     //   setVideoDetail({videoDetail: response.data.items[0].snippet})
  //     })
  //     .catch((error) => console.log(error));

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-end my-12">
        <div
          className="flex items-center w-3/5 h-auto bg-primary head-section-sm md:head-section-lg lg:w-1/2 lg:h-16"
          style={{ zIndex: 998 }}
        >
          <span className="py-2 ml-8 text-2xl font-hairline text-white uppercase font-roboto xl:text-4xl">
            Our <span className="font-bold">latest message</span>
          </span>
        </div>
      </div>
      <div className='w-full'>
        <Video />
      </div>
    </div>
  );
}
