import React, { useState } from "react";
import "../../styles/index.css";
import facebook from "../../apis/facebook";
import VideoPlayer from "../presentational/Video/VideoPlayer";
import VideoList from "../presentational/Video/VideoList";
import Loading from "../presentational/Loading";

function WatchOnline() {
	const [videos, setVideos] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedVideo, setSelectedVideo] = useState("");
	const [thumbnails, setThumbnails] = useState([]);
	const accessToken =
		"EAADPZAacSZCB0BALv84rEkEsUuYkTvYay6fgci4wo3y7lQ30eWbVIzSziAtkKFTidPX5ZCN4A2o8lxXvNgWcAzqNPjLroESxWXa0gQze0SVZAp3tc5eHG9ZANrPlBfZAmoEKkPc7O14ZCvjctaqHjgs1ZAjJvhN8FFLlIvtqhDHHGgZDZD";
	React.useLayoutEffect(() => {
		const fetchVideos = async () => {
			const getThumbnails = await facebook.get("/videos", {
				params: {
					access_token: accessToken,
					fields: "thumbnails"
				}
			});
			const result = await facebook.get("/videos", {
				params: {
					access_token: accessToken,
					fields:
						"created_time,description,source,title"
				}
			});
			//console.log(result);
			//console.log(getThumbnails);
			setThumbnails(prevState => [
				...prevState,
				getThumbnails.data.data
			]);
			setVideos(prevState => [
				...prevState,
				result.data.data
			]);
			setSelectedVideo(result.data.data[0]);
		};
		fetchVideos().then(() => setLoading(false));
	}, []);
	const handleVideoClick = video => {
		setSelectedVideo(video);
	};
	return (
		<div>
			{loading ? (
				<Loading />
			) : (
				<div className="flex flex-col w-full p-6 ">
					<div className="flex flex-col lg:flex-row">
						<div className="w-full lg:w-2/3 ">
							<VideoPlayer
								video={
									selectedVideo.source
								}
							/>
						</div>
						<div className="flex flex-col px-2 py-4 lg:hidden">
							<span className="font-semibold font-museo">
								Video
								Description
							</span>
							<span className="w-full text-lg font-light font-roboto">
								{
									selectedVideo.description
								}
							</span>
						</div>
						<div className="flex flex-col w-full lg:ml-6 lg:w-1/3">
							<span className="px-2 py-4 font-semibold lg:py-0 font-museo">
								More Messages
							</span>
							<VideoList
								videos={videos}
								thumbnails={
									thumbnails
								}
								handleVideoClick={
									handleVideoClick
								}
							/>
						</div>
					</div>
					<div className="hidden lg:py-2 lg:flex-col lg:flex">
						<span className="font-semibold font-museo">
							Video Description
						</span>
						<span className="w-2/3 text-lg font-light font-roboto">
							{
								selectedVideo.description
							}
						</span>
					</div>
				</div>
			)}
		</div>
	);
}

export default WatchOnline;
