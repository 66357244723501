import React, { useState } from "react";
import Tab from "../ui/Tab";
import TabButton from '../ui/TabButton';
import "../../styles/index.css";
export default function OrderOfService() {
  const [currentTab, setCurrentTab] = useState("Mombasa");
  const handleTab = (e) => {
    setCurrentTab(e.target.value);
  };
  return (
    <div className="flex flex-col w-full bg-secondary pb-12">
      <div
        className="my-12 bg-lightBlack head-section-inv-sm md:head-section-inv-lg w-3/5 lg:w-1/2 flex items-center"
        style={{ zIndex: 999 }}
      >
        <span className="ml-8 py-2 text-white font-hairline font-roboto xl:text-4xl text-2xl uppercase">
          Order of <span className="font-bold">Services</span>
        </span>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex flex-col items-center lg:flex-row lg:justify-center">
         <TabButton name='Mombasa' onClicked={handleTab} currentTab={currentTab} />
         <TabButton name='Nairobi' onClicked={handleTab} currentTab={currentTab}/>
         <TabButton name='Malindi' onClicked={handleTab} currentTab={currentTab}/>
        </div>
        <Tab title="Mombasa" currentTab={currentTab} />
        <Tab title="Nairobi" currentTab={currentTab} />
        <Tab title="Malindi" currentTab={currentTab} />
      </div>
    </div>
  );
}
