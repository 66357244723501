import React from "react";
import "../../../styles/index.css";

export default function FormField(props) {
	const { label, type, name, value} = props;
	return (
		<div className="flex flex-col items-center justify-between w-full py-2 text-lg lg:flex-row">
			<label
				className="font-semibold tracking-wider text-secondary font-museo"
				htmlFor={name}
			>
				{label}
			</label>
			<input
				type={type}
				id={name}
				value={value}
				onChange={props.handleChange}
				className="w-4/5 p-2 border-2 lg:w-2/3 focus:outline-none border-lightGrey rounded-md text-lightBlack font-montserrat"
				required
			/>
		</div>
	);
}
