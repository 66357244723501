import React from "react";
import "../../styles/index.css";

export default function TabButton(props) {
  const { name, onClicked, currentTab } = props;
  let buttonStyle =
    "font-montserrat py-2 px-2 hover:text-lightGrey transition-colors duration-300 ease-in bg-primary lg:w-1/5 w-10/12 text-2xl focus:outline-none";
  if (currentTab === name) {
    buttonStyle =
      "font-montserrat py-2 px-2 text-lightGrey transition-colors duration-300 ease-in bg-lightBlack lg:w-1/5 w-10/12 text-2xl focus:outline-none";
  }
  return (
    <button className={`${buttonStyle}`} value={name} onClick={onClicked}>
      {name}
    </button>
  );
}
