import React from "react";
import SocialFeed from './SocialFeeds';
import Location from './Location';
import HomeCarousel from './HomeCarousel';
import AboutSection from './AboutSection';
import OrderOfService from './OrderOfService';
import LatestMessageSection from './LatestMessageSection';
import "../../styles/index.css";

function Home() {
  return (
    <div>
      <HomeCarousel />
      <AboutSection />
      <OrderOfService/>
      <LatestMessageSection/>
      <SocialFeed/>
      <Location />
    </div>
  );
}

export default Home;
