import React, { useState } from "react";
import { createBrowserHistory } from "history";
import ReactModal from "react-modal";
import "../../styles/index.css";
import { MdClose } from "react-icons/md";

export default function Give() {
	const history = createBrowserHistory();
	const [giveForm, setGiveForm] = useState({
		name: "",
		type: "offering",
		amount: "",
		phone: "",
		email: ""
	});
	const handleChange = e => {
		setGiveForm({ ...giveForm, [e.target.name]: e.target.value });
	};
	const onSubmit = () => {
		console.log(giveForm);
	};
	return (
		<ReactModal
			isOpen
			style={{
				overlay: {
					display: "flex",
					justifyContent: "center",
					padding: "2em",
					backgroundColor: "rgba(35, 31, 32, 0.9)"
				}
			}}
			className="w-11/12 bg-white rounded-lg lg:w-1/2 focus:outline-none h-h50vh"
			contentLabel="Giving Form"
		>
			<div className="flex flex-col w-full p-4 ">
				<div className="flex justify-end w-full">
					<button
						className="text-4xl text-primary focus:outline-none"
						onClick={() => history.goBack()}
					>
						<MdClose />
					</button>
				</div>
				<div className="flex justify-between w-full p-6 font-museo">
					<div>
						<span className="text-lg">
							Give
						</span>
					</div>
					<div>
						<span className="p-2 text-gray-600 bg-gray-100">
							KSH
						</span>
					</div>
				</div>
				<div>
					<div className="px-6">
						<div className="flex flex-col">
							<div className="flex justify-center w-full p-4 bg-gray-300">
								<input
									type="number"
									placeholder="Amount"
									name="amount"
									className="w-1/4 px-1 mr-4 border-2 font-museo focus:outline-none"
									value={
										giveForm.amount
									}
									onChange={
										handleChange
									}
								/>
								<select
									className="w-2/3 text-xl font-museo focus:outline-none"
									id="givingType"
									name="type"
									value={
										giveForm.type
									}
									onChange={
										handleChange
									}
								>
									<option value="offering">
										Offering
									</option>
									<option value="tithe">
										Tithe
									</option>
									<option value="pledge">
										Pledge
									</option>
									<option value="prophetOffering">
										Prophet
										Offering
									</option>
									<option value="donation">
										Donation
									</option>
								</select>
							</div>
							<div className="flex py-4">
								<input
									type="text"
									className="w-1/2 p-1 mr-2 border-2 rounded-md focus:outline-none"
									placeholder="Name (optional)"
									value={
										giveForm.name
									}
									name="name"
									onChange={
										handleChange
									}
								/>
								<input
									type="number"
									className="w-3/4 p-1 border-2 rounded-md focus:outline-none"
									placeholder="Phone Number (required)"
									value={
										giveForm.phone
									}
									name="phone"
									onChange={
										handleChange
									}
								/>
							</div>
							<div className="py-4 ">
								<input
									type="email"
									className="w-full p-1 border-2 rounded-md focus:outline-none"
									placeholder="Email (optional)"
									name="email"
									value={
										giveForm.email
									}
									onChange={
										handleChange
									}
								/>
							</div>
							<div
								className="w-full py-3"
								style={{
									textAlign:
										"center"
								}}
							>
								<button
									type="submit"
									className="px-3 py-1 text-xl text-white rounded-full bg-primary hover:text-lightBlack transition ease-in duration-150 font-montserrat focus:outline-none"
									onClick={
										onSubmit
									}
								>
									Give
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}
