import React from "react";
import Carousel from "nuka-carousel";
import Banner4 from "../../assets/images/Banner-4.png";
import Banner2 from "../../assets/images/Banner-2.jpg";
import Banner3 from "../../assets/images/Banner-3.jpg";
import Banner1 from "../../assets/images/Banner-1.jpg";
import Banner5 from "../../assets/images/Banner-5.jpg";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "../../styles/index.css";

function HomeCarousel() {
  return (
    <Carousel
      autoplay={true}
      speed={500}
      wrapAround={true}
      heightMode="first"
      transitionMode="fade"
      renderCenterRightControls={({ nextSlide }) => (
        <button
          className="text-4xl outline-none text-lightBlack focus:outline-none"
          onClick={nextSlide}
        >
          <IoIosArrowForward />
        </button>
      )}
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          className="text-4xl outline-none text-lightBlack focus:outline-none"
          onClick={previousSlide}
        >
          <IoIosArrowBack />
        </button>
      )}
    >
      <img src={Banner4} alt="Banner-4" />
      <img src={Banner2} alt="Banner-2" />
      <img src={Banner3} alt="Banner-3" />
      <img src={Banner1} alt="Banner-1" />
      <img src={Banner5} alt="Banner-5" />
    </Carousel>
  );
}

export default HomeCarousel;
