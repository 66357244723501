import React from "react";
import MamaPapa from "../../assets/images/Mama&Papa.png";
import { Link } from "react-router-dom";
// import "animate.css/animate.min.css";
// import ScrollAnimation from 'react-animate-on-scroll';

import "../../styles/index.css";

export default function AboutSection() {
	// const offset = 90
	// const duration = 2
	return (
		<div className="flex flex-col w-full mb-4 lg:mb-8">
			<div className="flex justify-end my-12">
				<div className="flex items-center w-4/5 h-auto bg-primary head-section-sm md:head-section-lg lg:w-3/5 lg:h-16">
					<span className="py-2 ml-8 text-2xl font-hairline text-white uppercase xl:text-4xl font-roboto">
						Welcome to{" "}
						<span className="font-bold">
							Breakthrough Chapel
							Int'l
						</span>
					</span>
				</div>
			</div>
			<div className="flex flex-col items-center lg:flex-row lg:justify-center">
				<div className="flex justify-center w-full lg:w-5/12">
					{/* <ScrollAnimation animateIn='slideInUp' animatePreScroll={false} duration={duration} offset={offset}> */}
					<div className="w-4/5 lg:w-full">
						<img
							className="offset-image-sm lg:offset-image-lg"
							src={MamaPapa}
							alt="Papa & Mama"
						/>
					</div>
					{/* </ScrollAnimation> */}
				</div>
				<div className="flex flex-col w-full px-8 lg:w-5/12">
					<div className="py-2 lg:py-0">
						<span className="text-3xl font-bold uppercase font-museo">
							Pastor Wole & Favour
							Joseph
						</span>
					</div>
					<div>
						<span className="text-xl font-light font-museo">
							From the time of
							creation, God's will for
							man was a life of
							affluence. This glorious
							destiny was lost when
							man sinned. However,
							through the death and
							resurrection of our Lord
							Jesus Christ, man
							received redemption. By
							faith, every believer
							can now access this
							glorious destiny.
							Through the preaching of
							the word of faith and
							hope, We at Breakthrough
							Chapel have seen many
							lives being transformed.
							God has confirmed his
							word in our lives with
							signs and wonders.
							Financial positions have
							changed, families have
							been restored and
							diseases healed.{" "}
						</span>
					</div>
					<div className="flex justify-center w-full py-4">
						<Link
							to="/about"
							className="px-3 py-2 uppercase border-2 font-museo border-lightBlack hover:bg-lightBlack hover:text-primary transition-colors duration-300 ease-in"
						>
							About us
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
