import React from "react";
import "../../styles/index.css";

export default function Tab(props) {
  const { currentTab, title } = props;
  let hidden = "flex flex-col py-12 items-center text-2xl";
  if (currentTab !== title) {
    hidden = "hidden";
  }
  const Mombasa = () => {
    return (
      <div className="flex justify-center">
        <div className="font-museo text-lightGrey w-10/12 lg:w-3/5 bg-lightBlack">
          <div className={`${hidden}`}>
            <div>
              <span className="font-hairline text-secondary uppercase text-3xl">
                {title}
              </span>
            </div>
            <div className="text-2xl text-primary py-2">
              <span className="font-bold">Sunday Service</span>
            </div>
            <div>
              <span>
                1<sup>st</sup> Service 8:00AM
              </span>
            </div>
            <div>
              <span>
                2<sup>nd</sup> Service 10:00AM
              </span>
            </div>
            <div className="text-2xl text-primary py-2">
              <span className="font-bold">Thursday Service</span>
            </div>
            <div>
              <span>Dominion Service 5:45PM</span>
            </div>
            <div className="text-2xl text-primary py-2">
              <span className="font-bold">Saturday Service</span>
            </div>
            <div>
              <span>Hour of Prayer 4:00PM</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const NairobiMalindi = () => {
    return (
      <div className="flex justify-center">
        <div className="font-museo text-lightGrey w-10/12 lg:w-3/5 bg-lightBlack">
          <div className={`${hidden}`}>
            <div>
              <span className="font-hairline text-secondary uppercase text-3xl">
                {title}
              </span>
            </div>
            <div className="text-2xl text-primary py-2">
              <span className="font-bold">Sunday Service</span>
            </div>
            <div>
              <span>Sunday Service 9:00AM</span>
            </div>
            <div className="text-2xl text-primary py-2">
              <span className="font-bold">Wednesday Service</span>
            </div>
            <div>
              <span>Dominion Service 5:45PM</span>
            </div>
            <div className="text-2xl text-primary py-2">
              <span className="font-bold">Saturday Service</span>
            </div>
            <div>
              <span>Hour of Prayer 4:00PM</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  if (currentTab === "Mombasa") {
    return <Mombasa />;
  } else {
    return <NairobiMalindi />;
  }
}
