import React from "react";
import {
	FaFacebookSquare,
	FaTwitter,
	FaInstagram,
	FaYoutube
} from "react-icons/fa";
import "../../styles/index.css";
import Form from "../container/Form";
import contactUs from "../../assets/images/contact-us.jpg";

export default function Contact() {
	return (
		<div className="flex flex-col w-full">
			<div className="relative flex flex-col items-center justify-center w-full h-64 overflow-hidden">
				<div
					className="absolute inset-0 bg-fixed bg-cover filter-gray-blur transform scale-110 -z-1"
					style={{
						backgroundImage: `url(${contactUs})`
					}}
				></div>
				<div>
					<span className="text-2xl font-bold lg:text-5xl font-museo text-lightGrey">
						We'd love to hear from you
					</span>
				</div>
				<div>
					<span
						className="text-lg lg:text-2xl font-museo"
						style={{ color: "#d6cba7" }}
					>
						Whether you'd like to send a
						prayer request,
					</span>
				</div>
				<div>
					<span
						className="text-lg lg:text-2xl font-museo"
						style={{ color: "#d6cba7" }}
					>
						give feedback, or just to say
						hello
					</span>
				</div>
			</div>
			<section className="relative flex flex-col w-full h-screen overflow-hidden">
				<div className="absolute bottom-0 left-0 w-full bg-primary h-60 transform -skew-y-6 origin-top-right"></div>
				<Form />
				<div
					className="absolute hidden xl:block z-1"
					style={{ bottom: "23%", right: "20%" }}
				>
					<div className="">
						<span className="text-2xl font-semibold text-lightGrey font-montserrat">
							Talk to us on
						</span>
					</div>
					<div className="flex items-center py-2 text-4xl text-lightGrey">
						<div className="mr-2">
							<a
								href="https://www.facebook.com/breakthroughchapelintl"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaFacebookSquare />
							</a>
						</div>
						<div className="mr-2">
							<a
								href="https://twitter.com/Breakthrough254"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaTwitter />
							</a>
						</div>
						<div className="mr-2">
							<a
								href="https://www.instagram.com/breakthroughchapel_int/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaInstagram />
							</a>
						</div>
						<div className="mr-2">
							<a
								href="https://www.youtube.com/channel/UC8v3udD65nMBbLYFfhJJmoA"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaYoutube />
							</a>
						</div>
					</div>
					<div className="py-1">
						<hr />
					</div>
					<div className="flex flex-col text-xl">
						<div>
							<span className="font-museo text-lightGrey">
								Mailing Address:
							</span>
						</div>
						<div>
							<span className="font-montserrat text-lightBlack">
								82493 - 80100
								Mombasa, KENYA
							</span>
						</div>
						<div>
							<span className="font-montserrat text-lightBlack">
								Phone:{" "}
								<a href="tel:+254722354043">
									+254722354043
								</a>
							</span>
						</div>
						<div>
							<span className="font-montserrat text-lightBlack">
								Email:{" "}
								<a href="mailto:info@breakthroughchapel.co.ke">
									info@breakthroughchapel.co.ke
								</a>
							</span>
						</div>
					</div>
				</div>
			</section>
			<div className="flex flex-col w-full py-10 xl:hidden">
				<div
					className="flex items-center justify-center text-4xl text-secondary"
					style={{ marginTop: "5%" }}
				>
					<div className="mr-2">
						<a
							href="https://www.facebook.com/breakthroughchapelintl"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaFacebookSquare />
						</a>
					</div>
					<div className="mr-2">
						<a
							href="https://twitter.com/Breakthrough254"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaTwitter />
						</a>
					</div>
					<div className="mr-2">
						<a
							href="https://www.instagram.com/breakthroughchapel_int/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaInstagram />
						</a>
					</div>
					<div className="mr-2">
						<a
							href="https://www.youtube.com/channel/UC8v3udD65nMBbLYFfhJJmoA"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaYoutube />
						</a>
					</div>
				</div>
				<div className="flex flex-col items-center py-4 text-xl xl:hidden">
					<div>
						<span className="font-museo text-lightBlack">
							Mailing Address:
						</span>
					</div>
					<div>
						<span className="font-montserrat text-lightBlack">
							82493 - 80100 Mombasa,
							KENYA
						</span>
					</div>
					<div>
						<span className="font-montserrat text-lightBlack">
							Phone:
							<a href="tel: +254722354043">
								+254722354043
							</a>
						</span>
					</div>
					<div>
						<span className="text-lg font-montserrat text-lightBlack">
							Email:{" "}
							<a href="mailto:info@breakthroughchapel.co.ke">
								info@breakthroughchapel.co.ke
							</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
