import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/index.css";
import Home from "./components/presentational/Home";
import WatchOnline from "./components/container/WatchOnline";
import NavBar from "./components/presentational/Navigation/NavBar";
import AboutUs from "./components/presentational/AboutUs";
import Contact from "./components/presentational/Contact";
import MiniBar from "./components/presentational/Navigation/MiniBar";
import Footer from "./components/presentational/Footer";
import Give from "./components/container/Give";
import Ministry from "./components/presentational/Ministry";
import Loading from "./components/presentational/Loading";
import { NotificationContainer } from "react-notifications";

function App() {
	const [loading, setLoading] = useState(true);
	React.useLayoutEffect(() => {
		setTimeout(() => setLoading(false), 3800);
		const pictures = [
			"./assets/images/Banner-1.jpg",
			"./assets/images/Banner-2.jpg",
			"./assets/images/Banner-3.jpg",
			"./assets/images/Banner-4.png",
			"./assets/images/Banner-5.jpg",
			"./assets/images/contact-us.jpg",
			"./assets/images/Banner-10.jpg"
		];
		pictures.forEach(picture => (new Image().src = picture));
	}, []);
	return (
		<div>
			{loading ? (
				<Loading />
			) : (
				<Router>
					<div>
						<MiniBar />
						<NavBar />
					</div>
					<div>
						<Switch>
							<Route exact path="/">
								<Home />
							</Route>
							<Route path="/about">
								<AboutUs />
							</Route>
							<Route path="/watch-online">
								<WatchOnline />
							</Route>
							<Route path="/ministries">
								<Ministry />
							</Route>
							<Route path="/contact-us">
								<Contact />
							</Route>
							<Route
								path="/give"
								component={Give}
							/>
						</Switch>
					</div>
					<div>
						<Footer />
					</div>
					<NotificationContainer />
				</Router>
			)}
		</div>
	);
}
export default App;
