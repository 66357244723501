import React from "react";
import "../../../styles/index.css";
require("datejs");
const VideoItem = ({ id, video, handleVideoClick, thumbnails }) => {
	//const dateAdded = new Date(video.created_time);
	const dateAdded = Date.parse(video.created_time);
	return (
		<div
			className="flex flex-shrink-0 w-full px-2 m-1 cursor-pointer"
			onClick={() => handleVideoClick(video)}
		>
			<div className="w-1/2">
				<img src={thumbnails} alt="Sermon Thumbnails" />
			</div>
			<div className="flex flex-col w-1/2 px-2">
				<div className="h-12 overflow-hidden">
					<span className="text-base font-medium font-roboto line-clamp-2">
						{video.title}
					</span>
				</div>
				<div>
					<span className="text-sm font-light text-gray-700 font-roboto">
						Breakthrough Chapel Int'l
					</span>
				</div>
				<div>
					<span className="text-sm font-light text-gray-700 font-roboto">
						{dateAdded.toDateString()}
					</span>
				</div>
			</div>
		</div>
	);
};
export default VideoItem;
