import React from "react";
import Logo from "../../assets/images/logo.png";

function Footer() {
	return (
		<div className="flex flex-col items-center w-full bg-lightBlack">
			<div className="px-8 pt-8 text-center">
				<span
					style={{ color: "#5A5A5A" }}
					className="text-xl font-bold uppercase font-roboto lg:text-3xl"
				>
					Thanks for visiting Breakthrough Chapel
					International
				</span>
			</div>
			<div className="px-8 py-2 text-center lg:py-0">
				<span className="text-xl font-roboto text-grey">
					To receive up to date news and messages
					from Breakthrough Chapel Int'l, sign up
					for our newsletter.{" "}
				</span>
			</div>
			<div className="flex flex-col items-center w-full px-8 py-6 lg:flex-row lg:justify-center">
				<form
					method="post"
					action="https://www.breakthroughchapel.co.ke/mailman/subscribe/newsletter_breakthroughchapel.co.ke"
					style={{ borderColor: "#5A5A5A" }}
					className="flex w-full border-2 rounded-lg lg:w-1/2"
				>
					<input
						style={{
							borderColor: "#5A5A5A"
						}}
						className="w-2/3 p-4 text-sm truncate bg-transparent border-r-2 outline-none text-grey placeholder-grey font-roboto lg:text-xl"
						type="email"
						name="email"
						placeholder="Enter your e-mail address"
						required
					/>
					<button
						type="submit"
						className="w-1/3 px-6 text-sm text-grey font-roboto lg:text-xl"
					>
						Sign Up
					</button>
				</form>
			</div>
			<div>
				<img
					src={Logo}
					height={200}
					width={200}
					alt="Logo"
					loading="lazy"
				/>
			</div>
			<div
				style={{ backgroundColor: "#191616" }}
				className="flex justify-center w-full p-4 mt-12"
			>
				<span className="text-lg text-grey font-roboto">
					© {new Date().getFullYear()} Copyright -
					Breakthrough Chapel International
				</span>
			</div>
		</div>
	);
}

export default Footer;
