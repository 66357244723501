import React from "react";
import VideoItem from "./VideoItem";
import "../../../styles/index.css";

const VideoList = ({ videos, thumbnails, handleVideoClick }) => {
	const renderVideoItems = videos.map((videoItems, index) => {
		const thumbnailItems = thumbnails[index];
		return videoItems.map((video, index) => {
			const thumbnailObj = thumbnailItems[index];
			return (
				<VideoItem
					key={video.id}
					video={video}
					handleVideoClick={handleVideoClick}
					thumbnails={
						thumbnailObj.thumbnails.data[
							thumbnailObj.thumbnails
								.data.length - 1
						].uri
					}
					id={video.id}
				/>
			);
		});
	});
	return (
		<div
			style={{ height: "80vh" }}
			className="flex flex-col overflow-x-hidden overflow-y-auto"
		>
			{renderVideoItems}
		</div>
	);
};

export default VideoList;
