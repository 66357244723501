import React from "react";
import "../../styles/index.css";
import church from "../../assets/images/church.png";

export default function Loading() {
	return (
		<div className="flex items-center justify-center w-full h-screen overflow-hidden">
			<div id="preloader">
				<div className="circle">
					<img
						src={church}
						alt="Loading Screen"
					/>
				</div>
			</div>
		</div>
	);
}
