import React from "react";
import NavigationLink from "./NavigationLink";
import "../../../styles/index.css";

function MiniBar() {
	return (
		<div className="p-1 bg-lightBlack">
      <ul className="flex justify-end text-xs text-white uppercase">
				<NavigationLink
					navStyle={
						"mr-2 hover:border-b hover:border-white font-roboto"
					}
					location="/contact-us"
				>
					Contact Us
				</NavigationLink>
				{/*<NavigationLink*/}
					{/*navStyle={*/}
						{/*"hover:border-b hover:border-white font-roboto"*/}
					{/*}*/}
					{/*location="/account"*/}
				{/*>*/}
					{/*My Account*/}
				{/*</NavigationLink>*/}
			</ul>
		</div>
	);
}

export default MiniBar;
